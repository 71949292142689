// 课程详情，不展示内容管理
export const noContentManageMap = [1, 2, 3, 4, 5, 11]
// 课程详情，用户类型
export const courseDetailUserType = new Map([
  ['全部用户', 1],
  ['购买课程用户', 2],
  ['指定用户', 3]
])
export const showTypeMap = new Map([
  [1, '全部用户'],
  [2, '购买课程用户'],
  [3, '指定用户']
])
// 课程详情，标签类型
export const courseDetailLabelType = new Map([
  ['课程详情', 1],
  ['旧版目录', 2],
  ['伴读资料', 3],
  ['圈子', 4],
  ['相关推荐', 5],
  ['分阶段', 6],
  ['新版目录-分章节', 7],
  ['新版目录-不分章节', 8],
  ['图片', 9],
  ['按钮跳转', 10],
  ['包含课程', 11],
  
])
// 课程详情，标签类型
export const LabelTypeMap = new Map([
  [1, '课程详情'],
  [2, '旧版目录'],
  [3, '伴读资料'],
  [4, '圈子'],
  [5, '相关推荐'],
  [6, '分阶段'],
  [7, '新版目录-分章节'],
  [8, '新版目录-不分章节'],
  [9, '图片'],
  [10, '按钮跳转'],
  [11, '包含课程']
])

// 课程详情
export const courseDetailColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '标签名称',
    dataIndex: 'moduleName',
  },
  {
    title: '标签类型',
    dataIndex: 'moduleType',
    customRender: (text) => {
      return LabelTypeMap.get(+text)
    },
  },
  {
    title: '展示用户',
    dataIndex: 'showType',
    customRender: (text) => {
      return showTypeMap.get(+text)
    },
  },
  {
    title: '状态',
    dataIndex: 'status',
    customRender: (text) => {
      return +text === 0 ? '未上架' : '已上架'
    },
    width: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];

// 课程详情-标签
export const courseStageColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '阶段名称',
    dataIndex: 'stageTitle',
    with: '40%'
  },
  {
    title: '状态',
    dataIndex: 'status',
    customRender: text => {
      return +text ? '已上架' : '未上架'
    },
    with: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];
// 学习规划
export const studyPlanColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '阶段名称',
    dataIndex: 'stageTitle',
    width: '30%'
  },
  {
    title: '状态',
    dataIndex: 'putawayFlag',
    scopedSlots: { customRender: 'putawayFlag' },
    width: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];
// 学习规划-标签
export const studyPlanTagsColumns = [
  {
    title: '序号',
    dataIndex: 'number',
    scopedSlots: { customRender: 'number' },
  },
  {
    title: '标签名称',
    dataIndex: 'tagTitle',
    with: '40%'
  },
  {
    title: '状态',
    dataIndex: 'putawayFlag',
    scopedSlots: { customRender: 'putawayFlag' },
    with: '30%'
  },
  {
    title: '标签类型',
    dataIndex: 'tagType',
    scopedSlots: { customRender: 'tagType' },
    with: '30%'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];

// 学习规划-内容管理-章节管理
export const StudyContentLessonColumns = [
  {
    title: "序号",
    dataIndex: "number",
    key: "number",
    scopedSlots: { customRender: "number" },
  },
  {
    title: "课节名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "操作",
    key: "operation",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
]
// 学习规划-内容管理-添加课节
export const StudyContentSelectLessonColumns = [
  {
    title: "序号",
    dataIndex: "number",
    key: "number",
    scopedSlots: { customRender: "number" },
    width: '15%'
  },
  {
    title: "课节名称",
    dataIndex: "name",
    key: "name",
    width: '50%'
  },
  {
    title: "课节类型",
    dataIndex: "lessonsText",
    key: "lessonsText",
  },
]

// 排序字段验证
export const validateSort = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入排序'));
  } else if (isNaN(+value)) {
    callback(new Error('请输入合法数字'));
  } else {
    callback()
  }
}
// 验证课节内容字段
export const validateLessonsNo = (rule, value, callback) => {
  if (value === '' || value === undefined) {
    callback(new Error('请选择课节内容'));
  } else {
    callback()
  }
}
