<template>
  <!-- 章节管理组件 -->
  <div>
    <a-button type="primary" icon="plus"  @click="modelShow = true" style="margin-bottom:20px; margin-right: 20px;">添加章节</a-button>
    <a-button icon="plus" type="primary" @click="showAddModal(null)">
      添加课节
    </a-button>
   
    <draggable v-model="catalogueArr" group="site" animation="300" scrollSensitivity="0px" dragClass="dragClass" ghostClass="ghostClass" chosenClass="chosenClass"
            @start="catalogueStart"  @end="catalogueEnd">
      <transition-group>
        <div class="list" v-for="(item, index) in catalogueArr" :key="item.catalogueNo">
          <div class="big_list">
            <div class="list_left">
              {{ index + 1 }}、<span>{{ item.catalogueName }}</span>
            </div>
            <div class="list_right">
              <template>
                <a-icon type="edit" class="my_icon" @click="editClick(index, item)" />
              </template>
              <a-icon type="delete" class="my_icon" @click="catalogueDeleteClick(item)" />
            </div>
          </div>

          <a-form class="col drag-form">
            <draggable v-model="item['lessons']" group="site" animation="300" scrollSensitivity="0px" dragClass="dragClass" ghostClass="ghostClass" @change="dragLesson(item)" chosenClass="chosenClass"
                @start="dragLessonStart" @end="dragLessonEnd($event)">
              <transition-group>
                <div class="small_list" v-for="(item2, index) in item.lessons" :key="item2.refId">
                  <div class="small_list_left">{{ item2.lessonsName }}</div>
                  <div class="list_right">
                    <a @click="showAddModal(item2, item.catalogueNo)">编辑</a>
                    <a-button type="link" @click="deleteLesson(item2)">删除</a-button>
                  </div>
                </div>
              </transition-group>
            </draggable>
          </a-form>
        </div>
      </transition-group>
    </draggable>

    <!-- 新增章节弹窗 -->
    <a-modal
        title="添加编辑章节"
        :visible="modelShow"
        :footer="null"
        :destroy-on-close="true"
        @cancel="handleCancel"
      >
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item label="章节名称" ref="catalogueName" prop="catalogueName">
            <a-input v-model="ruleForm.catalogueName" placeholder="请输入章节名称" />
          </a-form-model-item>
          
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" @click="submitForm('ruleForm')">
              确定
            </a-button>
            <a-button style="margin-left: 10px" @click="handleCancel">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
    </a-modal>

    <!-- 添加课节 -->
    <AddLessonModal :visible="ModalVisible" :info="curRecord" :catalogue-list="catalogueArr" @fetch="fetchData" @close="() => ModalVisible = false" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import UpdataImg from "@/components/file/updataImg";
import {
  delCourseModuleCatalogue,
  addCourseModuleCatalogue,
  editCourseModuleCatalogue,
  courseModuleCatalogueList,
  delCourseModuleLessons,
  sortCourseModuleLessons,
  sortCourseModuleCatalogue
} from "@/request/api/coursManage"
import { message, Modal } from "ant-design-vue";
import { cloneDeep } from 'lodash'
import AddLessonModal from './AddLessonModal.vue';

export default {
  components: { UpdataImg, AddLessonModal, draggable },
  created () {
    this.fetchData()
  },
  data () {
    return {
      moduleId: this.$route.params.moduleId,
      modelShow: false,
      catalogueArr: [], // 章节的数组
      lessonDrag: true, // 拖拽
      lessonDragArr: [], // 拖拽的数组
      lessonDragItem: {}, //
      ruleForm: {
        catalogueName: '',
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      
      rules: {
        catalogueName: [
          { required: true, message: '请输入章节名称', trigger: 'blur' },
        ]
      },

      ModalVisible: false,
      curRecord: {},

      catalogueDrag: true,
      catalogueDragArr: [], // 拖拽的数组
    };
  },
  methods: {
    // 编辑课节的按钮
    showAddModal(record = null, catalogueNo) {
      const obj = {
        catalogueNo: '',
        lessonsNo: '',
        studyFlag: 0
      }
      if (record) {
        record.lessonsNo = [record.lessonsNo]
        record.catalogueNo = catalogueNo
      }
      this.curRecord = record || obj
      this.ModalVisible = true
    },
    // 编辑按钮
    editClick (index, item) {
      this.ruleForm = cloneDeep(item)
      this.modelShow = true
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          +this.ruleForm.catalogueNo ? this.edit() : this.add()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 添加
    async add () {
      const params = {
        moduleId: this.moduleId,
        catalogueName: this.ruleForm.catalogueName
      }
      const { code } = await addCourseModuleCatalogue(params)
      if (code !== 200) return
      message.success('添加成功')
      this.handleCancel()
      this.fetchData()
    },
    // 编辑
    async edit () {
      const params = {
        ...this.ruleForm
      }
      const { code } = await editCourseModuleCatalogue(params)
      if (code !== 200) return
      message.success('修改成功')
      this.handleCancel()
      this.fetchData()
    },

    handleCancel () {
      this.modelShow = false
      // 取消/关闭
      this.ruleForm.catalogueName = ''
      this.ruleForm.catalogueNo = ''
    },
    // 章节目录删除按钮
    catalogueDeleteClick (arr) {
      if (arr.lessons.length > 0) {
        Modal.confirm({
          title: "提示",
          content: "此章节下面有课节,不能删除",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
        });
      } else {
        Modal.confirm({
          title: "提示",
          content: "删除后相关数据无法恢复, 是否确认删除",
          okText: "确认",
          okType: "danger",
          cancelText: "取消",
          onOk: async () =>{
            const { code} = await delCourseModuleCatalogue({ catalogueNo: arr.catalogueNo })
            if (code !== 200) return  
            message.success("删除目录成功");
            this.catalogueArr = this.catalogueArr.filter(
              (item) => item.catalogueNo !== arr.catalogueNo
            );  
          },
        });
      }
    },
    async fetchData () {
      const params = {
        withLessons: 1,
        moduleId: this.moduleId
      }
      const {code ,data} = await courseModuleCatalogueList(params)
      if (code !== 200) return
      this.catalogueArr = data
    },
    deleteLesson(record) {
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk: async () => {
          const { code } = await delCourseModuleLessons({ refId: record.refId})
          if (code !== 200)  return
          message.success("删除成功");
          this.fetchData()
        },
      });
    },
    // 目录排序
    catalogueEnd(event) {
      this.$nextTick(() => {
        this.catalogueDrag = false
        let arr = [];
        this.catalogueArr.map((item, index) => {
          arr.push({ catalogueNo: item.catalogueNo, sort: index });
        })
        sortCourseModuleCatalogue({
          moduleId: this.moduleId,
          catalogueNos: arr,
        }).then(({ code, data }) => {
          if (code == 200) {
            message.success("排序成功", 1);
          }
        });
      });
    },
    catalogueStart() {
      this.lessonDrag = false;
      this.catalogueDrag = true
    },
    dragLessonStart() {
      this.catalogueDrag = false
      this.lessonDrag = true
    },
    // 拖拽中
    dragLesson (data) {
      this.lessonDragArr.push(data);
      if (this.lessonDragArr.length === 1) {
          this.lessonDragItem = this.lessonDragArr[this.lessonDragArr.length - 1];
      } else {
          this.lessonDragItem = this.lessonDragArr[this.lessonDragArr.length - 2];
      }
    },
    // 拖拽结束
    dragLessonEnd (e) {
      this.$nextTick(() => {
        this.lessonDrag = false;
        if (this.lessonDragItem.lessons) {
          let arr = [];
          this.lessonDragItem.lessons.map((item, index) => {
            arr.push({ lessonsNo: item.lessonsNo, sort: index });
          });
          sortCourseModuleLessons({
            moduleId: this.moduleId,
            catalogueNo: this.lessonDragItem.catalogueNo,
            lessonsNos: arr,
          }).then(({ code, data }) => {
            if (code == 200) {
              message.success("排序成功", 1);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/*定义要拖拽元素的样式*/
.ghostClass {
    background-color: #f2f2f2 !important;
}
.chosenClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
}
.dragClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    background-image: none !important;
}
.col {
    flex: 1;
    border-radius: 5px;
    background: #fff;
}
.drag-form {
    /deep/ span {
        display: block;
        min-height: 1px;
    }
}
.my_icon {
    font-size: 26px;
    margin-right: 20px;
}

.list {
    margin-bottom: 10px;
    .big_list {
        width: 100%;
        height: 60px;
        line-height: 60px;
        background-color: #f2f2f2;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border: 1px solid #cccccc59;
        .list_left {
            margin-left: 20px;
            min-width: 270px;

            .input {
                position: relative;

                span {
                    position: absolute;
                    top: -22px;
                    right: 6px;
                }
            }
        }
        .list_right{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    .small_list {
        border: 1px solid #cccccc59;
        border-top: none;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        .small_list_left {
            margin-left: 20px;
        }
        .small_list_right {
            margin-right: 20px;
        }
    }
}

// 新增章节的输入框
.add_input {
    position: relative;
    span {
        position: absolute;
        top: 0;
        right: 6px;
    }
}
.input_box {
    width: 70%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ant-input {
        width: 400px;
        margin-left: 20px;
    }
}
// 新增章节
.add_chapter {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #f2f2f2;
    padding-left: 20px;
    .add {
        font-size: 20px;
        color: #409eff;
        margin-right: 10px;
    }
}
</style>
