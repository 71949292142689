<template>
  <div>
    <!-- 添加编辑 -->
    <a-modal
      title="添加/编辑课节"
      :visible="visible"
      :footer="null"
      :destroy-on-close="true"
      @cancel="onCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item v-if="isAdd" label="课节名称" ref="lessonsNo" prop="lessonsNo">
          <span v-if="ruleForm.lessonsNo">已选{{ selectedRowKeys.length }}节课</span>
          <a-button type="primary" v-else @click="handleClickAdd">添加</a-button>
        </a-form-model-item>
        <a-form-model-item v-else label="课节名称" ref="lessonsNo" prop="lessonsNo">
          <span > {{ ruleForm.lessonsName }}</span>
        </a-form-model-item>
        
        <a-form-model-item  v-if="catalogueFlag" label="所属章节" ref="catalogueNo" prop="catalogueNo">
          <a-select v-model="ruleForm.catalogueNo" default-value="课程-不分章节" style="width: 160px">
            <a-select-option v-for="item in selectTree"  :key="item.catalogueNo" :value="item.catalogueNo">
              {{ item.catalogueName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="是否试听" name="studyFlag" prop="studyFlag">
          <a-radio-group v-model="ruleForm.studyFlag">
            <a-radio :value="0">否 </a-radio>
            <a-radio :value="1"> 是 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button type="primary" @click="submitForm('ruleForm')">
            确定
          </a-button>
          <a-button style="margin-left: 10px" @click="onCancel">
            取消
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 选择课节 -->
    <a-modal
      title="课节列表"
      :visible="lessonVisible"
      :destroy-on-close="true"
      :width="600"
      @cancel="onCancelLesson"
      @ok="onSelectLesson"
    >
      <div style="display: flex">
        <a-input v-model="filter.searchVal" style="width:200px; margin-right:20px" placeholder="请输入课节名称" />
        <a-button type="primary" @click="onSearch">搜索</a-button>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="lessonColumn"
        :data-source="lessonListData"
        :pagination="false"
        :loading="tableLoading"
        row-key="lessonsNo"
        :row-selection="{ onChange: onSelectChange }"
        :scroll="{y: 300}"
      >
        <span slot="number" slot-scope="number, record, index">
          <span>{{ index + 1 }}</span>
        </span>
        
      </a-table>
      
      <!-- 分页 -->
      <MyPagination
        :count="count"
        :pageNo="filter.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
      />
    </a-modal>
  </div>
</template>
<script>
import { StudyContentSelectLessonColumns,validateLessonsNo } from '../../constant'
import { courseModuleCatalogueList, GetCourseLessonsResultApi, editCourseModuleLessons, addCourseModuleLessons } from '@/request/api/coursManage'
import MyPagination from '@/components/pagination/MyPagination'
import { cloneDeep } from 'lodash'
export default {
  components: { MyPagination },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    catalogueList: {
      type: Array,
      default: () => {[]}
    },
    info: {
      type: Object,
      default: ()  => ({})
    }
  },
  watch: {
    visible: function(val) {
      this.isAdd = !this.info.lessonsNo
      this.selectTree = this.catalogueList
      if (val) this.ruleForm = cloneDeep(this.info)
    }
  },
  data() {
    return {
      isAdd: false,
      count: 0,
      lessonListData: [],
      tableLoading: false,
      lessonColumn: StudyContentSelectLessonColumns,
      lessonVisible: false,
      moduleId: this.$route.params.moduleId,
      courseNo: this.$route.params.courseNo,
      selectTree: this.catalogueList,
      catalogueFlag: true,
      modalVisible: false,
      ruleForm: this.info,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {
        lessonsNo: [
          { required: true, validator: validateLessonsNo, trigger: ['change', 'blur'] },
        ],
        studyFlag: [
          { required: true, message: '请选择是否试听',trigger: 'change' },
        ],
      },
      filter: {
        pageNo: 1,
        pageSize: 20,
        searchVal: ''
      },
      selectedRowKeys: []
    }
  },
  created() {
    const catalogueFlag = this.$route.query?.catalogueFlag
    this.catalogueFlag = +catalogueFlag === 1
    if (this.catalogueFlag) {
      this.rules.catalogueNo = [
        { required: true, message: '请输选择章节', trigger: 'change' },
      ]
    }
    if (!this.catalogueList) this.fetchData()
  },
  methods: {
    async fetchData () {
      const params = {
        withLessons: 0,
        moduleId: this.moduleId
      }
      const {code ,data} = await courseModuleCatalogueList(params)
      if (code !== 200) return
      this.selectTree = data
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.isAdd ? this.add() : this.edit()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async edit() {
      const params = {
        refId: this.ruleForm.refId,
        catalogueNo: this.ruleForm.catalogueNo,
        studyFlag: +this.ruleForm.studyFlag
      }
      const { code } = await editCourseModuleLessons(params)
      if (code !== 200) return 
      this.$message.success('修改成功')
      this.$emit('fetch')
      this.onCancel()
    },
    async add() {
      const params = {
        moduleId: this.moduleId,
        lessonsNo: this.ruleForm.lessonsNo,
        catalogueNo: this.ruleForm.catalogueNo,
        studyFlag: +this.ruleForm.studyFlag
      }
      const { code } = await addCourseModuleLessons(params)
      if (code !== 200) return 
      this.$message.success('添加成功')
      this.$emit('fetch')
      this.onCancel()
    },
    // 取消/关闭
    onCancel() {
      this.ruleForm = {
        lessonsName: '',
        lessonsNo: '',
        catalogueNo: '',
        catalogueNo: '',
      }
      this.$emit('close')
    },
    onSelectLesson() {
      this.ruleForm.lessonsNo = this.selectedRowKeys
      this.onCancelLesson()
    },
    onCancelLesson() {
      this.lessonVisible = false
      this.filter = {
        pageNo: 1,
        pageSize: 20,
        searchVal: ''
      }
    },
    handleClickAdd() {
      this.lessonVisible = true
      this.getLessonData()
    },
    // 获取课节列表
    async getLessonData() {
      const params = {
        courseNo: this.courseNo,
        ...this.filter,
        name: this.filter.searchVal
      }
      const { code, data } = await GetCourseLessonsResultApi(params)
      if (code !== 200) return 
      this.lessonListData = data.data
      this.count = data.count
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.filter.pageNo = current;
      this.filter.pageSize = pageSize;
      this.getLessonData();
    },
    
    onSearch() {
      this.filter.pageNo = 1
      this.getLessonData();
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>