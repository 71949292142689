<template>
  <!-- 内容管理页面 -->
  <div style="margin-top: -20px">
    <ChapterManage v-if="catalogueFlag"/>
    <LessonsManage v-else :catalogueFlag="catalogueFlag"/>
  </div>
</template>

<script>
import ChapterManage from "./components/ChapterManage";
import LessonsManage from "./components/LessonsManage";
export default {
  components: { ChapterManage, LessonsManage },
  data() {
    return {
      catalogueFlag: +this.$route.query.catalogueFlag, // 是否分章节
      courseNo: this.$route.params.courseNo, // 课程编号
    };
  }
};
</script>
 
<style lang="less" scoped>
.ant-tabs {
  margin-top: 20px;
}
</style>