<template>
  <!-- 课节管理组件 -->
  <div>
    <!-- 顶部 -->
    <div class="top">
      <div class="top_left">
        <a-button icon="plus" type="primary" @click="showAddModal(null)"> 添加课节 </a-button>
      </div>
      <div class="top_rigiht">
        <a-input
          ref="userNameInput"
          v-model="keys"
          placeholder="请输入课节名称"
          style="width: 200px"
          @pressEnter="searchClick"
        >
          <a-icon slot="prefix" type="search" />
        </a-input>
        <a-button
          icon="search"
          type="primary"
          @click="searchClick"
          :disabled="isDisableSearch"
          >搜索</a-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <div class="list">
      <a-form class="col drag-form">
        <draggable v-model="tableData" group="site" animation="300" scrollSensitivity="0px" dragClass="dragClass" ghostClass="ghostClass" @start="() => drag = true" chosenClass="chosenClass" @end="onEnd()">
          <transition-group>
            <div class="small_list" v-for="(item, index) in tableData" :key="item.refId">
              <div class="small_list_left">{{ item.lessonsName }}</div>
              <div class="list_right">
                <a @click="showAddModal(item, item.catalogueNo)">编辑</a>
                <a-button type="link" @click="delClick(item)">删除</a-button>
              </div>
            </div>
          </transition-group>
        </draggable>
      </a-form>
    </div>
   
    <AddLessonModal :visible="ModalVisible" :info="curRecord" @fetch="fetchData" @close="() => ModalVisible = false" />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { message, Modal } from "ant-design-vue";
import {
  courseModuleLessonsList,
  delCourseModuleLessons,
  sortCourseModuleLessons
} from "@/request/api/coursManage";
import AddLessonModal from './AddLessonModal.vue';
let selectWrap 
export default {
  components: { AddLessonModal, draggable },
  created() {
    this.fetchData()
  },
  mounted() {
    this.$nextTick(() => {
      selectWrap = document.querySelector("#course_list");
      selectWrap.addEventListener("scroll", this.handleScroll);
    })
  },
  beforeDestroy() {
    selectWrap.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      ModalVisible: false,
      pageNo: 1,
      pageSize: 20,
      moduleId: this.$route.params.moduleId,
      keys: "", // 搜索的值
      isDisableSearch: false, // 是否禁用搜索框
      tableLoading: true, // 表格是否加载
      // 表格数据
      tableData: [],
      curRecord: {},
      drag: false,
      count: 0
    };
  },
  methods: {
    handleScroll() {
      const scrollDistance = selectWrap.scrollHeight - selectWrap.scrollTop - selectWrap.clientHeight;
      if (scrollDistance <= 10 && this.count > this.tableData.length) {
        ++this.pageNo;
        let countAll = Math.ceil(this.count / this.pageSize);
        if (countAll < this.pageNo) return;
        this.fetchData();
      }
    },
    //   搜索的按钮
    searchClick() {
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 2000)
      this.page = 1
      this.tableData = []
      this.fetchData()
    },
    // 编辑的按钮
    showAddModal(record = null) {
      const obj = {
        lessonsNo: '',
        studyFlag: 0
      }
      this.curRecord = record || obj
      this.ModalVisible = true
    },
    // 删除的按钮
    delClick(record) {
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk: async () => {
          const { code } = await delCourseModuleLessons({ refId: record.refId})
          if (code !== 200)  return
          message.success("删除成功");
          this.fetchData()
        },
      });
    },
    // 获取课节列表的函数
    async fetchData() {
      const params = {
        moduleId: this.moduleId,
        page: this.pageNo,
        pageSize: this.pageSize,
        keys: this.keys, // 课节名称
      }
      const { code, data } = await courseModuleLessonsList(params)
      this.tableLoading = false;
      if (code !== 200) return
      this.tableData = data.data || []
      // this.tableData = this.tableData.concat(_data)
      this.count = data.count
    },
    onEnd() {
      this.$nextTick(() => {
        this.drag = false
        let arr = [];
        this.tableData.map((item, index) => {
          arr.push({ lessonsNo: item.lessonsNo, sort: index });
        })
        sortCourseModuleLessons({
          moduleId: this.moduleId,
          catalogueNo: '',
          lessonsNos: arr
        }).then(({ code, data }) => {
          if (code == 200) {
            message.success("排序成功", 1);
          }
        });
      });
    }
  }
}
</script>
 
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .top_rigiht {
    span {
      margin-right: 10px;
    }
  }
}

/deep/.ant-select-dropdown-placement-bottomLeft {
  top: 96px !important;
}
/*定义要拖拽元素的样式*/
.ghostClass {
    background-color: #f2f2f2 !important;
}
.chosenClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
}
.dragClass {
    background-color: #f2f2f2 !important;
    opacity: 1 !important;
    box-shadow: none !important;
    outline: none !important;
    background-image: none !important;
}
.col {
    flex: 1;
    border-radius: 5px;
    background: #fff;
}
.drag-form {
    /deep/ span {
        display: block;
        min-height: 1px;
    }
}
.list {
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #cccccc59;
    .small_list {
        border-top: 1px solid #cccccc59;
        width: 100%;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        .small_list_left {
            margin-left: 20px;
        }
        .small_list_right {
            margin-right: 20px;
        }
    }
}

</style>